import React from 'react'
import '../../css/Approach/WhyAreWe.css';
import WhyAreWePic from "../../../images/here.jpg";

const WhyAreWe = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-evenly why_are_we_bg">
                    <div className="col-md-5">
                        <h2 className="text-start mt-5 mb-2 fw-bold text-uppercase ">
                            first, why are we all here? 
                        </h2>
                        <p className="lead mt-3 mb-3 ">
                            Our journey begins with understanding the core purpose behind your design and development challenges. Together, we articulate business-centric goals and leverage our expertise to uncover realistic yet impactful solutions.
                        </p>

                        <h3 className="text-start text-uppercase fw-bold small">
                            what's happening:
                        </h3>
                        <p>
                            <span>– Discovery workshops</span><br />
                            <span>– Design + technology audits</span><br />
                            <span>– Stakeholder alignment</span><br />
                            <span>– Benchmarking + SMART goal setting</span>
                        </p>

                    </div>

                    <div className="col-md-3 text-center">

                        <figure className="mt-5">
                            <img className="img-responsive" src={WhyAreWePic} alt="Why are we pic" />

                            <blockquote className="blockquote mt-2">
                                <p className="whyarewefontsize">
                                    "A lack of specific, measurable goals we are all working toward is the primary reason any initiative fails. It's the equivalent of heading west on The Oregon Trail and saying, We'll just know it when we get there."
                                </p>
                            </blockquote>

                            <figcaption className="blockquote-footer text-end mt-2">
                                <cite title="Source Title">Eugene, Director of Product</cite>
                            </figcaption>

                        </figure>

                    </div>
                </div>
            </div>
        </>
    )
}
        
export default WhyAreWe