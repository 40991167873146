import React from 'react'
import '../../css/Approach/WeSeeWhatWorks.css';
import WeSeeWhatWorksPic from "../../../images/domore.jpg";

const WeSeeWhatWorks = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-evenly we_see_what_works_bg">
                    <div className="col-md-3 text-center">
                        <figure className="mt-5">
                            <img className="img-responsive" src={WeSeeWhatWorksPic} alt="We See What Works Pic" />

                            <blockquote className="blockquote mt-2">
                                <p className="WeSeeWhatWorksfontsize">
                                    "This is the really fun part and what makes a partnership long term."
                                </p>
                            </blockquote>

                            <figcaption className="blockquote-footer text-end mt-2">
                                <cite title="Source Title">Arthur, Director of Product & Digital Strategy</cite>
                            </figcaption>

                        </figure>

                    </div>

                    <div className="col-md-5">
                        <h2 className="text-start mt-5 mb-2 fw-bold text-uppercase ">
                            WE see what works, and do more of it. 
                        </h2>
                        <p className="lead mt-3 mb-3 ">
                            We accompany you in the realm of data, continuously measuring, interpreting, and crafting new initiatives to sustain the transformation. Expect us to ask not just "what's next," but more importantly, "why," as we drive forward together.
                        </p>

                        <h3 className="text-start text-uppercase fw-bold small">
                            what's happening:
                        </h3>
                        <p>
                            <span>– Training + stakeholder on-boarding</span><br />
                            <span>– Data analytics</span><br />
                            <span>– User experience research</span><br />
                            <span>– Iteration</span>
                            <span>– Optimization</span><br />
                        </p>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default WeSeeWhatWorks