import React from 'react'
import '../../css/Home/HeroSection.css';

const HeroSection = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row hero-section">
                    <div className="col-md-12">	
                        <h1>Empowering Your Digital<br />Transformation</h1>
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default HeroSection