import React, { useEffect} from 'react';
import '../../css/Home/BubblesSection.css';
// import BubblesPic from "../../../images/mission-bubbles-desktop.png";
import BubblesJson from "../../../json/bubbles_mobile.json";
import Lottie  from 'lottie-web';

const BubblesSection = () => {

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: document.getElementById('bubbles-container'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: BubblesJson
        });
        return () => {
            animation.destroy();
        };
    }, []);


    return (
        <>
            <div className="container-fluid">
                <div className="row bubble_bg">
                    <div className="col-md-12">
                        <div className="bubbles">
                            {/* <picture> 
                                <img className="bubbles-img-overlay" src={BubblesPic} alt="Bubbles"  />
                            </picture> */}

                            <div id="bubbles-container"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default BubblesSection