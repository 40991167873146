import React from 'react'
import '../../css/Home/ThinksEnvisionSection.css';
import ThinkPic from "../../../images/think.png";
import EnvisionPic from "../../../images/envision.png";
import BuildPic from "../../../images/build.png";

const ThinksEnvisionSection = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-evenly pt-3 pb-3 thinks">

                    <div className="col-md-3 text-center mb-3">
                        <div className="icon">
                            <img src={ThinkPic} alt="think" />
                        </div>

                        <h3>WE THINK WITH YOU</h3>

                        <div className="text">
                            Innovation Strategy<br />
                            Digital Product Strategy<br />
                            Technology Architecture<br />
                            Information Architecture							
                        </div>
                    </div>

                    <div className="col-md-4 text-center mb-3">
                        <div className="icon">
                            <img src={EnvisionPic} alt="" />
                        </div>

                        <h3>WE ENVISION WITH YOU</h3>

                        <div className="text">
                            Digital Product Design<br />
                            UX Research & Design<br />
                            Website Design<br />
                            Brand Identity Design							
                        </div>
                    </div>

                    <div className="col-md-3 text-center mb-3">
                        <div className="icon">
                            <img src={BuildPic} alt="" />
                        </div>

                        <h3>WE BUILD WITH YOU</h3>

                        <div className="text">
                            Web App Development<br />
                            Magento Development<br />
                            Wordpress Development<br />
                            Mobile App Development							
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default ThinksEnvisionSection