import React from 'react'
import HeroSection from './includes/Contact/HeroSection';
import ProblemsSection from './includes/Home/ProblemsSection';
// import CommentSection from './includes/Contact/CommentSection';
import WhatYouGet from './includes/Home/WhatYouGet';
import ScrollToFirstComponent from './includes/ScrollToTop/ScrollToFirstComponent';

export default function Contact(){

    return (
        <>
            {/* scroll to the first component's position when the page loads */}
            <div ref={ScrollToFirstComponent().firstComponentRef} />

            {/* hero section */}
            <HeroSection />

            {/* problems section */}
            <ProblemsSection />

            {/* what our client say section */}
            {/* <CommentSection /> */}

            {/* What you get Section */}
            {/* <WhatYouGet />    */}
        </>
    )
}