import React from 'react'
import '../../css/About/DifferentAboutCynobSection.css';

export default function DifferentAboutCynobSection(){
    return (
        <>
            <div className="container-fluid">
                <div className="row different_about_cynob_bg">
                    <div className="col-md-12">
                        <h3 className="display-6 text-center mt-5 mb-2 fw-bold">
                            What’s Different About Cynob ? 
                        </h3>
                    </div>
                </div>
                <div className="row  different_about_cynob_bg justify-content-evenly">
                    <div className="col-md-3 text-start mt-3 mb-3" >
                        <h4 className="mb-3 fw-bold">Engineering with passion</h4>
                        <p>
                            As a network of passionate innovators, we tackle complex client challenges with bold solutions. Our commitment to exploring new technologies and designing tailored solutions has resulted in an outstanding<strong> client satisfaction </strong>, earning their trust and willingness to recommend our services.                               
                        </p>
                    </div>

                    <div className="col-md-3 text-start mt-3 mb-3">
                        <h4 className="mb-3 fw-bold">Performance focus</h4>
                        <p>
                            As your dedicated partner, we prioritize your satisfaction above all else. We don't consider a project complete until both parties are 100% happy. With a remarkable <strong>client satisfaction rate of over 97%</strong>, we have earned the reputation of being a trusted and strategic technology partner for our clients.
                        </p>
                    </div>

                    <div className="col-md-3 text-start mt-3 mb-3">
                        <h4 className="mb-3 fw-bold">Dedication to success</h4>
                        <p>
                            Our unwavering dedication to success empowers our clients to stay ahead of the curve. We prioritize your immediate needs and long-term goals, understanding that success extends beyond financial gains. For us, success is three-fold: benefiting our clients, their customers, and the communities they impact, creating a positive ripple effect.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}