import React from 'react'
import '../../css/Approach/WeBuild.css';
import WeBuildPic from "../../../images/then.jpg";

const WeBuild = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-evenly we_build_bg">
                    <div className="col-md-5">
                        <h2 className="text-start mt-5 mb-2 fw-bold text-uppercase ">
                            THEN: We Build.
                        </h2>
                        <p className="lead mt-3 mb-3 ">
                            In this phase, we sprint from proof of concept to minimum viable product, focusing on high-value components. Our goal is to deliver a production-ready release with the biggest impact, exceeding client expectations.
                        </p>

                        <h3 className="text-start text-uppercase fw-bold small">
                            what's happening:
                        </h3>
                        <p>
                            <span>– Roadmapping + prioritization</span><br />
                            <span>– Design sprints</span><br />
                            <span>– Early validation + testing</span><br />
                            <span>– Proof of concept → MVP → Production</span>
                        </p>

                    </div>

                    <div className="col-md-3 text-center">

                        <figure className="mt-5">
                            <img class="img-responsive" src={WeBuildPic} alt="We Build Pic" />

                            <blockquote className="blockquote mt-2">
                                <p className="WeBuildfontsize">
                                    "As we build, we balance what's doable alongside what's impactful. This is an exercise in prioritization and transparency. We share where we're at and how we're going to do it. If there’s a risk to any solution, you'll know."
                                </p>
                            </blockquote>

                            <figcaption className="blockquote-footer text-end mt-2">
                                <cite title="Source Title">Ross, CTO</cite>
                            </figcaption>

                        </figure>

                    </div>
                </div>
            </div>
        </>
    )
}
        
export default WeBuild