import React from 'react'
import '../../css/Approach/WantToLearnMore.css';
import WantToLearnMorePic from "../../../images/learnmore.jpeg";

const WantToLearnMore = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-evenly pt-3 pb-5 want_to_learn_more_bg">
                    <div className="col-md-5 text-center mb-3">
                        <figure className="mt-5">
                            <img className="img-responsive" src={WantToLearnMorePic} alt="Wants to learn more pic" />
                        </figure>
                    </div>

                    <div className="col-md-4 mb-3">
                        
                        <h2 className="text-start mt-5 mb-5 fw-bold text-uppercase ">
                            Want to learn more about how we achieve success with our clients?
                        </h2>
                    
                        <p className="text-start mt-2 mb-2">
                            We believe a thoughtful company builds things that work with purpose rather than just work. Download our white paper to learn more about our methodology.
                        </p>
                        
                        <a className="btn btn-outline-dark mt-5 p-2 fw-bold" href="contact" role="button">
                            Want to learn more 
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default WantToLearnMore