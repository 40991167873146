import React from 'react'
import '../../css/Home/WeAskWhySection.css';

const WeAskWhySection = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row we_ask_why justify-content-evenly pt-5 pb-5">
                    <div className="col-md-5 text-center mb-3">
                        <h2 className="subtitle display-6 text-center mt-2 mb-2 fw-bold">
                            WE ASK WHY,NOT WHAT
                        </h2>
                    </div>

                    <div className="col-md-4 mb-3">
                        <p className="text-start mt-2 mb-2">
                            When we agree to build something, we’re agreeing to build the future of your business. So we always start by asking: Why?
                        </p>
                        
                        <a className="btn btn-outline-dark mt-5 p-2 fw-bold" href="contact" role="button">
                            Learn More About Our Approach
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default WeAskWhySection