import React from 'react'
import '../../css/About/WhoWeAreSection.css';
import WhatYouGetPic from "../../../images/what_you_get.jpg";

export default function WhoWeAreSection(){
    return (
        <>
            <div className="container-fluid">
                <div className="row who_we_are_bg">
                    <div className="col-md-12">
                        <h1 className="about_title">Who we are</h1>          
                    </div>
                </div>
                <div className="row justify-content-evenly who_we_are_bg">

                    <div className=" col-md-5 col-xs-12 col-sm-12 mb-5 text">                    
                        <img className="img-fluid steps_imgWrap1" src={WhatYouGetPic} alt="What you get pic" />                 
                    </div>

                    <div className=" col-md-6 col-xs-12 col-sm-12">

                        <div className="step_item1">
                            <h3 className="step_title1">Our story</h3>
                            <div className="step_text1">
                                <p>
                                    Formerly known as NetAttingo Technologies we started our journey in 2012. At Cynob, we are at the forefront of transforming businesses and individuals into the trailblazers of the digital era. As a leading innovator in the tech industry, our mission is to empower our clients with cutting-edge solutions, enabling them to navigate the dynamic landscape of the digital world with confidence and success.
                                    <br /><br />
                                    At Cynob, we understand that every journey is unique, and our holistic approach ensures that our clients receive the utmost support and guidance along their path to success. From innovative software development to transformative digital strategies, we strive to make a lasting impact on businesses of all scales, empowering them to thrive in the digital landscape.
                                    <br /><br />
                                    Join us in shaping the future of technology as we continue to evolve, innovate, and lead the way into a visionary digital era. Together, let's embrace the limitless potential of tomorrow and turn it into a reality today.
                                </p>
                            </div>
                        </div>
                        
                        <div className="step_item1">
                            <h3 className="step_title1">We are dedicated to</h3>
                            <div className="step_text1">
                                <p>
                                    Building unique and immersive worlds through expert craftsmanship, and to create challenging designs that pull the rug from out under your feet.
                                </p>
                            </div>
                        </div>

                        <div className="step_item1">
                            <h3 className="step_title1">Our mission</h3>
                            <div className="step_text1">
                                <p>
                                    To leverage our unique multi cultural setting to build a truly creative game studio with industry leading talent, in one of the most vibrant cities in the world.
                                </p>
                            </div>
                        </div>

                        <div className="step_cta1">
                            Have a question?  &nbsp;
                            <a href="contact" className="link1">
                                <span className="link_text1">Speak to an expert</span>
                            </a>
                        </div>

                    </div>

                </div>  
            </div>
        </>
    )
}