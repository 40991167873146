import React from 'react'
import '../../css/Approach/WeAskWhy.css';

const WeAskWhy = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-evenly we_ask_why_bg">
                    <div className="col-md-8">
                        <h1 className="subtitle display-5 text-center mt-5 mb-4 pt-5 fw-bold">
                            WE ASK WHY,NOT WHAT
                        </h1>
                        <p className="lead mt-5 mb-5 ">
                            At our company, we prioritize thoughtfulness and meticulousness in every aspect of our work. We believe in asking the question "why?" and going even further by asking it repeatedly. Our goal is to delve deep, exploring the intricacies of your business, in order to discover the most efficient, impactful, and meaningful strategies to propel your organization forward. We are committed to understanding your unique challenges and objectives, allowing us to provide tailored solutions that address your specific needs. 
                        </p>            
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default WeAskWhy