import React from 'react'
import WhoWeAreSection from './includes/About/WhoWeAreSection';
import DifferentAboutCynobSection from './includes/About/DifferentAboutCynobSection';
import TeamsSection from './includes/About/TeamsSection';
import ScrollToFirstComponent from './includes/ScrollToTop/ScrollToFirstComponent';

export default function About(){

    return (
        <>
            {/* scroll to the first component's position when the page loads */}
            <div ref={ScrollToFirstComponent().firstComponentRef} />

            {/* Who we are section */}
            <WhoWeAreSection />

             {/* different about cynob Section */}
            <DifferentAboutCynobSection />

            {/* Teams Section */}
            <TeamsSection />        
        </>
    )
}