import { Routes, Route} from "react-router-dom";
import Home from "../../Home";
import Approach from "../../Approach";
import About from "../../About";
import Contact from "../../Contact";
import Login from "../../Login";
import Signup from "../../Signup";
import ErrorPage from "../ErrorPage/Errorpage";
import Logout from "../../Logout";

export default function Routing(){
    return(
        <Routes> 
            <Route path="/" element={<Home />} />
            <Route path="/approach" element={<Approach />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )
}