import React from 'react'
import { NavLink } from 'react-router-dom';
import '../../css/Home/WhatYouGet.css';
import WhatYouGetPic from "../../../images/what_you_get.jpg";

const WhatYouGet = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row what_you_get_bg">
                    <div className="col-md-12">
                        <h2 className="display-6 mt-4 mb-4 fw-bold">
                            What You Get With Cynob Software Development Services
                        </h2>  
                    </div> 
                </div> 

                <div className="row justify-content-between">
                    <div className=" col-md-6 col-xs-12 col-sm-12 order-2 order-md-1">

                        <div className="step_item">
                            <h3 className="step_title">Superior quality of work</h3>
                            <div className="step_text">
                                <p>Our team of experts includes some of the best and brightest minds in the industry, with years of experience in their respective fields. We leverage this expertise to ensure that every aspect of your project is executed flawlessly, from initial planning and development to testing and deployment.</p>
                            </div>
                        </div>
                        
                        <div className="step_item">
                            <h3 className="step_title">Flexible scaling</h3>
                            <div className="step_text"><p>Our talent pool includes software specialists with a wide range of skills and expertise. We work closely with you to identify the specific skills and experience required for your project, and we build a custom offshore software development team that is the perfect fit for your needs.</p>
                            </div>
                        </div>

                        <div className="step_item">
                            <h3 className="step_title">Geographical proximity</h3>
                            <div className="step_text"><p>                            
                            Our experts are highly skilled and experienced in their respective fields, and they are committed to adopting your culture and transferring process and behavior-change knowledge to your internal staff. We believe that this approach leads to better collaboration, increased efficiency, and a more successful outcome for your business
                            </p>
                            </div>
                        </div>

                        <div className="step_cta">
                            Have a question?
                            <NavLink to="/Contact" className="link">
                                <span className="link_text"> Speak to an expert</span>
                            </NavLink>
                        </div>

                    </div>

                    <div className=" col-md-5 col-xs-12 col-sm-12 order-1 order-md-2 mb-5 text">
                        <img className="img-responsive steps_imgWrap" src={WhatYouGetPic} alt="What You Get" />                    
                    </div>

                </div>
            </div>
        </>
    )
}
        
export default WhatYouGet