import React, {useState} from 'react';
import '../../css/Contact/HeroSection.css';

export default function HeroSection(){
    const [user2, setUser2] = useState({
        name: "", email: "", phone: "", country: "", company: "", quentions: ""
    });

    let name, value;

    const handleInputs2 = (e) => {
        console.log(e);
        name = e.target.name;
        value = e.target.value;

        setUser2({...user2, [name]:value});
    }

    const askDeveloper = async (e) => {
        e.preventDefault();
        const { name, email, phone, country, company, quentions } = user2;

        const res = await fetch(`${process.env.REACT_APP_HTTP_KEY}/askDeveloper`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type" : "application/json"
            },
            body: JSON.stringify({
                name, email, phone, country, company, quentions
            })
        });
        const data = await res.json();

        if(data.status === 201) {            
            window.alert(data.message);
            console.log(data.message);

            // Clear the input fields after successful save
            setUser2({
                name: "",
                email: "",
                phone: "",
                country: "",
                company: "",
                quentions: "",
            });
        }else{
            window.alert(data.error);
            console.log(data.error);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row hero-section1 justify-content-evenly">
                    <div className="col-md-12">	

                        <div className="row justify-content-between">
                            <div className="col-md-5">
                                <h1>
                                    CYNOB: Your Software Development Outsourcing Partner
                                </h1>  
                                <p>
                                    Accelerate your digital transformation, scale development efforts flexibly and cost-efficiently
                                </p>
                            </div>

                            <div className="col-md-5 text-center">
                                <div className="herosectionbgcolor">
                                    <h3 className="form-head">
                                        Work with Top Software Developers
                                    </h3>
                                    <form method="POST">
                                        <input value={user2.name} onChange={handleInputs2} name="name" id="name" type="text" className="medium" maxlength="80" aria-required="true" aria-invalid="false" placeholder="Your full name" />
                                    
                                        <input value={user2.email} onChange={handleInputs2} name="email" id="email" type="email" className="medium" aria-required="true" aria-invalid="false" placeholder="Your work email" />
                                    
                                        <input value={user2.phone} onChange={handleInputs2} name="phone" id="phone" type="text" className="medium" aria-required="true" aria-invalid="false" placeholder="Your phone number" />
                                    
                                        <input value={user2.country} onChange={handleInputs2} name="country" id="country" type="text" className="medium" maxlength="80" aria-required="true" aria-invalid="false" placeholder="Country" />
                                    
                                        <input value={user2.company} onChange={handleInputs2} name="company" id="company" type="text" className="medium" maxlength="80" aria-required="true" aria-invalid="false" placeholder="Company" />
                                    
                                        <textarea value={user2.quentions} onChange={handleInputs2} name="quentions" id="quentions" className="textarea medium" aria-required="true" aria-invalid="false" rows="10" cols="24" placeholder="How can we help you ?" onkeyup="if (this.scrollHeight > this.clientHeight) this.style.height = this.scrollHeight + 'px';"></textarea>
                                    
                                        <label for="choice" id="labelchoice" className="labelChoice text-start">
                                            <input name="choice" id="choice"type="checkbox" value="I want to receive news and updates once in a while" />
                                            <span className="inputchoice" >I want to receive news and updates once in a while</span>
                                        </label>

                                        <div className="form_info">
                                            We will add your info to our CRM for contacting you regarding your request. For more info please consult our 
                                            <a href="dummy.com">privacy policy</a>
                                        </div>

                                        <input type="submit" id="gform_submit_button_7" className="contact_form_button button" value="Send Message" onClick={askDeveloper} />

                                    </form>
                                </div>                       
                            </div>

                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}