import React, {useState} from 'react'
import '../../css/About/TeamsSection.css';
import Team1Pic from "../../../images/team1.jpg";
import afroz_alam_pic from "../../../images/afroz_alam_pic.jpg";
import shabila_pic from "../../../images/shabila_pic.jpg";
import AftabPic from "../../../images/aftabpic.jpg";
import DeepakPic from "../../../images/deepakpic.png";
import AbhishekPic from "../../../images/abhishekpic.jpg";
import JashimPic from "../../../images/jashimpic.jpg";

export default function TeamsSection(){
    const [selectedIds, setSelectedIds] = useState([]);

    const handleClick = id => {
        if (selectedIds.includes(id)) {
          setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        } else {
          setSelectedIds([...selectedIds, id]);
        }
    };

    
    return (
        <>
            <div className="container-fluid">
                <div className="row teams_bg">
                    <div className="col-md-12">
                        <h3 className="display-4 mt-5 mb-2 fw-bold">
                            Teams
                        </h3>
                    </div>
                </div>
                <div className="row teams_bg">
                    <div className="col-md-12">

                        <div className="row d-flex justify-content-evenly">
                            <div className="col-md-3 col-sm-6 col-xs-12 p-0">
                                <div className="border border-dark dots p-0 mt-3 mb-3">
                                    <img className={selectedIds.includes("1") ? "rounded-circle opacity" : "rounded-circle"} src={afroz_alam_pic} alt="Team img" />
                                    <p className={selectedIds.includes("1") ? "img_text_hover img_text_show" : "img_text_hover"}>
                                        Visionary leader. Strategic thinker. Decisive problem solver. Effective communicator. Inspirational motivator. Adaptable. Empowers teams. Achieves goals with integrity.
                                    </p>
                                    <div className="border border-dark dot">
                                        <p onClick={() => handleClick("1")}>...</p>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h3>Afroz Alam</h3> 
                                    <p>CO-Founder</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 col-xs-12 p-0">
                                <div className="border border-dark dots p-0 mt-3 mb-3">
                                    <img className={selectedIds.includes("2") ? "rounded-circle opacity" : "rounded-circle"} src={shabila_pic} alt="Team img" />
                                    <p className={selectedIds.includes("2") ? "img_text_hover img_text_show" : "img_text_hover"}>
                                        Has passion for transforming ideas into cutting-edge solutions, driving technological advancement and business growth through a blend of strategic vision and hands-on expertise.
                                    </p>
                                    <div className="border border-dark dot">
                                        <p onClick={() => handleClick("2")}>...</p>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h3>Shabila Fatma</h3> 
                                    <p>CO-Founder</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 col-xs-12 p-0">
                                <div className="border border-dark dots p-0 mt-3 mb-3">
                                    <img className={selectedIds.includes("3") ? "rounded-circle opacity" : "rounded-circle"} src={AftabPic} alt="Team img" />
                                    <p className={selectedIds.includes("3") ? "img_text_hover img_text_show" : "img_text_hover"}>
                                        Tech visionary. Innovates solutions. Drives tech strategy. Implements cutting-edge tech. Collaborates with teams.
                                    </p>
                                    <div className="border border-dark dot" target="1">
                                        <p onClick={() => handleClick("3")}>...</p>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h3>Aftab Alam</h3> 
                                    <p>Engineering Head</p>
                                </div>
                            </div>                                                        
                        </div>

                        <div className="row d-flex justify-content-evenly"> 
                            <div className="col-md-3 col-sm-6 col-xs-12 p-0">
                                <div className="border border-dark dots p-0 mt-3 mb-3">
                                    <img className={selectedIds.includes("4") ? "rounded-circle opacity" : "rounded-circle"} src={DeepakPic} alt="Team img" />
                                    <p className={selectedIds.includes("4") ? "img_text_hover img_text_show" : "img_text_hover"}>
                                        Design maven. Crafts intuitive experiences. Drives user-centric approach. Innovates UI/UX strategies.
                                    </p>
                                    <div className="border border-dark dot">
                                        <p onClick={() => handleClick("4")}>...</p>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h3>Deepak Kumar</h3> 
                                    <p>UI & UX Head</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 col-xs-12 p-0">
                                <div className="border border-dark dots p-0 mt-3 mb-3">
                                    <img className={selectedIds.includes("5") ? "rounded-circle opacity" : "rounded-circle"} src={AbhishekPic} alt="Team img" />
                                    <p className={selectedIds.includes("5") ? "img_text_hover img_text_show" : "img_text_hover"}>
                                        Tech-savvy, dedicated to pioneering disruptive technologies, cultivating a culture of innovation, and steering companies towards success in the rapidly evolving landscape of the digital age.
                                    </p>
                                    <div className="border border-dark dot">
                                        <p onClick={() => handleClick("5")}>...</p>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h3>Abhishek Kumar</h3> 
                                    <p>Software Engineer</p>
                                </div>
                            </div>                   

                            <div className="col-md-3 col-sm-6 col-xs-12 p-0">
                                <div className="border border-dark dots p-0 mt-3 mb-3">
                                    <img className={selectedIds.includes("6") ? "rounded-circle opacity" : "rounded-circle"} src={JashimPic} alt="Team img" />
                                    <p className={selectedIds.includes("6") ? "img_text_hover img_text_show" : "img_text_hover"}>
                                        Results-driven Digital Marketing Manager with a proven track record in crafting dynamic online strategies, leveraging data-driven insights to optimize campaigns, and achieving measurable growth for businesses across diverse industries. 
                                    </p>
                                    <div className="border border-dark dot" target="6">
                                        <p onClick={() => handleClick("6")}>...</p>
                                    </div>
                                </div>
                                <div className="img-text">
                                    <h3>Md Jashim Akhtar</h3> 
                                    <p>Digital Marketing Manager</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}