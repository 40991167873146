import React from 'react'
import WeAskWhy from './includes/Approach/WeAskWhy';
import WhyAreWe from './includes/Approach/WhyAreWe';
import WhatWillTake from './includes/Approach/WhatWillTake';
import WeBuild from './includes/Approach/WeBuild';
import WeSeeWhatWorks from './includes/Approach/WeSeeWhatWorks';
import WantToLearnMore from './includes/Approach/WantToLearnMore';
import ScrollToFirstComponent from './includes/ScrollToTop/ScrollToFirstComponent';

export default function Approach(){

    return (
        <>
            {/* scroll to the first component's position when the page loads */}
            <div ref={ScrollToFirstComponent().firstComponentRef} />

            {/* we ask why section */}
            <WeAskWhy />
            
            {/* why are we section */}
            <WhyAreWe />

            {/* what will take section */}
            <WhatWillTake />

            {/* we build section */}
            <WeBuild />
            
            {/* we see what works section */}
            <WeSeeWhatWorks />

            {/* want to learn more section */}
            <WantToLearnMore />        
        </>
    )
}