import React from 'react'
import HeroSection from './includes/Home/HeroSection';
import WhatYouGet from './includes/Home/WhatYouGet';
import BubblesSection from './includes/Home/BubblesSection';
import ProblemsSection from './includes/Home/ProblemsSection';
import ThinksEnvisionSection from './includes/Home/ThinksEnvisionSection';
import WeAskWhySection from './includes/Home/WeAskWhySection';
import ScrollToFirstComponent from './includes/ScrollToTop/ScrollToFirstComponent';

export default function Home(){

    return (
        <>
            {/* scroll to the first component's position when the page loads */}
            <div ref={ScrollToFirstComponent().firstComponentRef} />

            {/* hero section */}
            <HeroSection />

             {/* What you get Section */}
            <WhatYouGet />

            {/* bubbles Section */}
            <BubblesSection />

            {/* problems section */}
            <ProblemsSection />

            {/* think, envision section */}
            <ThinksEnvisionSection />

            {/* We ask why section */}
            <WeAskWhySection />
        </>
    )
}