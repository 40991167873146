import React from 'react'
import '../../css/Home/ProblemsSection.css';

const ProblemsSection = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row problem_bg">
                    <div className="col-md-12">
                        <h3 className="display-6 text-center mt-5 mb-2 fw-bold">
                            Problems We'll Help You Solve 
                        </h3>
                    </div>
                </div>
                <div className="row  problem_bg justify-content-evenly">
                    <div className="col-md-3 text-start mt-3 mb-3" >
                        <h3 className="mb-3 fw-bold">Fill your IT resourcing gaps with top experts</h3>
                        <p>
                            Our experts are among the best and brightest in the industry, and we carefully select and vet each member of our team to ensure that they meet our high standards for quality and expertise. Whether you need help with a specific project or require ongoing support for your software development needs, our offshore software development team can partner with you at any stage of your SDLC.
                        </p>
                    </div>

                    <div className="col-md-3 text-start mt-3 mb-3">
                        <h3 className="mb-3 fw-bold">Speed up delivery & reduce time-to-market</h3>
                        <p>Through outsourcing, we have the expertise and resources to effectively tackle even the most intricate software development tasks, allowing you to witness progress at twice the speed compared to in-house development. By entrusting us with the translation of your requirements, you can allocate more of your valuable time towards enhancing your core business operations and maximizing operational efficiency.
                        </p>
                    </div>

                    <div className="col-md-3 text-start mt-3 mb-3">
                        <h3 className="mb-3 fw-bold">Optimise development costs</h3>
                        <p>
                            When you choose a trusted software development outsourcing company like ours, you can achieve your software vision while optimizing costs by up to 50% compared to building an in-house team. With CYNOB as your offshore software development partner, you can efficiently manage development expenses and avoid the time and financial investment required for recruitment and upskilling. By leveraging our expertise, you can improve operational efficiency, reduce overhead costs, and ultimately boost your revenue.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default ProblemsSection