import React from 'react'
import '../../css/Approach/WhatWillTake.css';
import WhatWillTakePic from "../../../images/take.jpg";

const WhatWillTake = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-evenly what_will_take_bg">
                    <div className="col-md-3 text-center">
                        <figure className="mt-5">
                            <img className="img-responsive" src={WhatWillTakePic} alt="What will take pic" />

                            <blockquote className="blockquote mt-2">
                                <p className="WhatWillTakefontsize">
                                    "We'll always tell you if there are better ways to get where you want to go. It's the best type of reality check. That's what partners are for."
                                </p>
                            </blockquote>

                            <figcaption className="blockquote-footer text-end mt-2">
                                <cite title="Source Title">Irene, Director of Project Management</cite>
                            </figcaption>

                        </figure>

                    </div>

                    <div className="col-md-5">
                        <h2 className="text-start mt-5 mb-2 fw-bold text-uppercase ">
                            Next: What will it take? 
                        </h2>
                        <p className="lead mt-3 mb-3 ">
                            After setting goals, we co-create the strategy by analyzing user stories and data, challenging assumptions. We build the team, plan, and timeline while keeping a sane and flexible scope, allowing us to stay agile and responsive.
                        </p>

                        <h3 className="text-start text-uppercase fw-bold small">
                            what's happening:
                        </h3>
                        <p>
                            <span>– Audience definition</span><br />
                            <span>– Impact mapping</span><br />
                            <span>– Digital strategy</span><br />
                            <span>– Scope definition</span>
                            <span>– Concept development</span><br />
                            <span>– Project management + Dev ops</span>
                            <span>– Team resourcing + onboarding</span>
                        </p>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
        
export default WhatWillTake